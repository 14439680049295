import 'tailwindcss/dist/base.min.css'
import './src/fonts/Mont-Black.otf'
import './src/fonts/Mont-Bold.otf'
import './src/fonts/Mont-BookItalic.otf'
import './src/fonts/Mont-ExtraLight.otf'
import './src/fonts/Mont-ExtraLightItalic.otf'
import './src/fonts/Mont-Heavy.otf'
import './src/fonts/Mont-HeavyItalic.otf'
import './src/fonts/Mont-Light.otf'
import './src/fonts/Mont-LightItalic.otf'
import './src/fonts/Mont-Regular.otf'
import './src/fonts/Mont-RegularItalic.otf'
import './src/fonts/Mont-SemiBold.otf'
import './src/fonts/Mont-SemiBoldItalic.otf'
import './src/fonts/Mont-Thin.otf'
import './src/fonts/Mont-ThinItalic.otf'
import React from "react"
import { Provider } from "react-redux"
import createStore from "./src/state/createStore"

// Wrapping the Gatsby app with the Provider component
export const wrapRootElement = ({ element }) => {
  const store = createStore()
  return (
    <Provider store={store}>
      {element}
    </Provider>
  )
}